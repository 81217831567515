import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useContext, lazy, Suspense, useCallback, useEffect } from "react";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import "./style/app.css";
import "./style/adminhome.css";
import "./style/inPlay.css";
import "./style/odds.css";
import Spinner from "./components/Spinner";
import axios from "axios";
import API_BASE_URL from "./constant/Api_base_url";
import { ReqCallContext, ReqCallProvider } from "./context/ReqCallContext";
import socketIOClient from "socket.io-client";
import ENDPOINT from "./constant/Endpoint";
const Main = lazy(() => import("./components/Main"));
const Login = lazy(() => import("./components/Login"));

function App() {
  const [userContext, setUserContext] = useContext(AuthContext);
  const [reqCall, setReqCall] = useContext(ReqCallContext);
  const navigate = useNavigate();
  function openNav() {
    document.getElementById("mySidebar").style.transition = "0.3s ease";
    document.getElementById("mySidebar").style.left = "0";
  }

  function closeNav() {
    document.getElementById("mySidebar").style.transition = "none";
    document.getElementById("mySidebar").style.left = "-100%";
  }

  const verifyUser = async () => {
    try {
      if (userContext.token) {
        const response = await axios.post(
          API_BASE_URL + "/auth/checkUserActive",
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          const data = response.data;
          setUserContext((oldValues) => {
            return { ...oldValues, token: data.token };
          });
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        setUserContext((oldValues) => {
          return { ...oldValues, details: null, token: null };
        });
      }
    }
  };
  useEffect(() => {
    verifyUser();
  }, [reqCall]);
  const syncLogout = useCallback((event) => {
    if (event.key === "userLogout") {
      // If using react-router-dom, you may call history.push("/")
      window.location.reload();
      navigate("/");
    }
  }, []);
  useEffect(() => {
    window.addEventListener("storage", syncLogout);
    return () => {
      window.removeEventListener("storage", syncLogout);
    };
  }, [syncLogout]);
  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);

    if (userContext.details) {
      if (userContext.details.username && userContext.details.level > 0) {
        socket.emit("login", {
          username: userContext.details.username,
          level: userContext.details.level,
        });
      }
    }
    return () => {
      socket.disconnect();
    };
  }, [userContext.details]);
  return (
    <div className="app">
      <Suspense fallback={<Spinner color="#222" style={{ marginTop: 20 }} />}>
        {userContext.token ? (
          <Main
            userContext={userContext}
            setUserContext={setUserContext}
            openNav={openNav}
            closeNav={closeNav}
          />
        ) : (
          <div>
            <div className="content non-user">
              <Routes>
                <Route
                  exact
                  path="/"
                  index
                  element={
                    <Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <Login />
                    </Suspense>
                  }
                />

                <Route path="*" element={<Login />} />
              </Routes>
            </div>
          </div>
        )}
      </Suspense>
    </div>
  );
}

const AppWrapper = () => (
  <AuthProvider>
    <ReqCallProvider>
      <Router>
        <App />
      </Router>
    </ReqCallProvider>
  </AuthProvider>
);
export default AppWrapper;
